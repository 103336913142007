import "./App.css";

function App() {
  return (
    <div className="App">
      <header id="header">
        <h1>JAAMs Cakery</h1>
        <p>
          Elgin's Favorite Homemade Treats!
          <br />
          <br />
          <button
            class="call-button"
            onClick={() => (window.location.href = "tel:+1630-526-3239")}
          >
            Text me @ (630) 526-3239
          </button>
          <br />
          <br />
          <a href="https://facebook.com/jaamscakery">View Menu</a>
        </p>
      </header>
      <p>Cakes are made to order. Text me to check availability.</p>

      <footer id="footer">
        <ul class="icons">
          <li>
            <a
              href="https://instagram.com/jaamscakery"
              class="icon brands fa-instagram"
            >
              <span class="label">Instagram</span>
            </a>
          </li>
          <li>
            <a
              href="https://facebook.com/jaamscakery"
              class="icon brands fa-facebook"
            >
              <span class="label">Facebook</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.google.com/maps/place/JAAMs+Cakery/@42.0281325,-88.3672734,15z/data=!4m6!3m5!1s0x880f1b9b68fe0657:0x18c4b07e2b4b1a66!8m2!3d42.0281325!4d-88.3672734!16s%2Fg%2F11y310jbth"
              class="icon fa-envelope"
            >
              <span class="label">Google Maps</span>
            </a>
          </li>
          <li>
            <a href="mailto:jaamscakery@gmail.com" class="icon brands fa-email">
              <span class="label">Email: jaamscakery@gmail.com</span>
            </a>
          </li>
        </ul>
        <ul class="icons" style={{ fontSize: "0.5em" }}>
          <li>
            Copyright © {new Date().getFullYear()} by JAAMs Cakery, All Rights
            Reserved.
          </li>
          <li>
            <a href="./privacy.html">Privacy Policy</a>
          </li>
          <li>
            <a href="./terms.html">Terms of use</a>
          </li>
        </ul>
      </footer>
    </div>
  );
}

export default App;
